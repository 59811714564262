<template>
  <div class="teachingManagement">
    <div class="tab1">
      <!-- <el-radio-group fill="#3EA671" v-for="roleName in roleNameList" :key="roleName" v-model="radio1">
        <el-radio-button v-if="roleName === '申请'" label="申请"></el-radio-button>
        <el-radio-button v-if="roleName === '审核'" label="审核"></el-radio-button>
        <el-radio-button v-if="roleName === '查看'" label="查看"></el-radio-button>
      </el-radio-group> -->

      <el-button-group v-for="roleName in roleNameList"
                       :key="roleName"
                       class="marginBottom">
        <el-button v-if="roleName === '申请'"
                   :type="applyVisible ? 'success' : ''"
                   @click="loadLeave(1, 1)">申请
        </el-button>
        <el-button v-if="roleName === '审核'"
                   :type="examineVisible ? 'success' : ''"
                   @click="loadLeave(1, 2)">审核
        </el-button>
        <!-- <el-button
          v-if="roleName === '查看'"
          :type="seeVisible ? 'success' : ''"
         @click="loadLeave(1, 3)">查看 </el-button> -->
      </el-button-group>

      <!-- <el-button-group class="marginBottom">
        <el-button
          :type="applyVisible ? 'success' : ''"
          @click="loadLeave(1, 1)"
          >申请
        </el-button>
        <el-button
          :type="examineVisible ? 'success' : ''"
          @click="loadLeave(1, 2)"
          >审核
        </el-button>
        <el-button :type="seeVisible ? 'success' : ''" @click="loadLeave(1, 3)"
          >查看
        </el-button>
      </el-button-group> -->

      <div class="flex-box">
        <div class="left">
          <el-button v-if="pageType === 1"
                     type="success"
                     @click="addLeave">+请假申请</el-button>
        </div>
        <div class="flex-box">
          <el-date-picker v-model="query.queryTime"
                          type="daterange"
                          class="header-query-frame"
                          range-separator="至"
                          value-format="yyyy-MM-dd"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>

          <!-- <el-date-picker
            class="picker"
            v-model="query.start_time"
            type="datetime"
            placeholder="选择开始时间"
          >
          </el-date-picker>
          <el-date-picker
            class="picker"
            v-model="query.end_time"
            type="datetime"
            placeholder="选择结束时间"
          >
          </el-date-picker> -->
          <!-- <el-input
            placeholder="申请人"
            class="picker"
            v-model="queryByCondition.people"
          ></el-input> -->

          <!-- 申请 -->
          <el-select @change="seeChange"
                     clearable
                     @clear="loadLeave(1, pageType)"
                     class="picker"
                     v-model="query.status"
                     placeholder="请选择状态">
            <el-option v-for="item in apllyStatusList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>

          <el-button type="primary"
                     class="picker"
                     @click="loadLeave(1, pageType)">查 询</el-button>
        </div>
      </div>
      <el-table ref="leaveRef"
                :data="leaveTable">
        <el-table-column v-if="pageType !== 1"
                         label="申请人姓名"
                         prop="mname"
                         align="center"
                         key="mname">
        </el-table-column>
        <el-table-column label="请假类型"
                         align="center"
                         key="type">
          <template slot-scope="scope">
            <div v-for="item in leaveTypeList"
                 :key="item.id">
              <div v-if="scope.row.type == item.id">{{ item.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="开始时间"
                         prop="begin_date"
                         align="center"
                         key="begin_date"></el-table-column>
        <el-table-column label="结束时间"
                         prop="end_date"
                         align="center"
                         key="end_date"></el-table-column>
        <el-table-column label="时长"
                         prop="duration"
                         align="center"
                         key="duration"></el-table-column>
        <el-table-column v-if="pageType !== 2"
                         label="审批人"
                         align="center"
                         key="reviewer">
          <template slot-scope="scope">
            <div v-for="item in scope.row.list"
                 :key="item.id">
              {{ item.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="发布时间"
                         prop="opertime"
                         align="center"
                         key="opertime"></el-table-column>
        <el-table-column label="状态"
                         align="center"
                         key="status">
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.status === 0">{{scope.row.statusStr}}</el-tag>
            <el-tag size="mini"
                    v-else-if="scope.row.status === 1"
                    type="success">{{scope.row.statusStr}}</el-tag>
            <!-- <el-tag size="mini" v-else type="warning">{{scope.row.statusStr}}</el-tag> -->
            <el-tag size="mini"
                    v-else-if="scope.row.status === 2"
                    type="danger">{{scope.row.statusStr}}</el-tag>
            <el-tag size="mini"
                    v-else-if="scope.row.status === 3"
                    type="warning">{{scope.row.statusStr}}</el-tag>
          </template>
        </el-table-column>

        <!-- <el-table-column label="状态" align="center" key="status">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.status === 0">申请中</el-tag>
            <el-tag
              size="mini"
              v-else-if="scope.row.status === 1"
              type="success"
              >审批通过</el-tag
            >
            <el-tag size="mini" v-else type="warning">审批不通过</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="请假原因"
                         prop="reason"
                         align="center"
                         key="reason"></el-table-column>
        <!-- <el-table-column
          label="附件"
          prop="url"
          align="center"
          key="url"
        ></el-table-column> -->
        <el-table-column label="附件"
                         align="center">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px"
                      v-if="scope.row.url"
                      :src="scope.row.photoUrl"
                      :preview-src-list="scope.row.photoUrlList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="270px"
                         align="center"
                         key="operation">
          <template slot-scope="scope">
            <div v-if="pageType === 1">
              <!-- <el-button
                :disabled="isTrueOrFalse(scope.row.begin_date) || scope.row.status !== 0"
                size="mini"
                type="primary"
                @click="leavePopup(scope.row, 1)"
                >编辑
              </el-button> -->
              <el-button :disabled="isTrueOrFalse(scope.row.begin_date) || scope.row.status !== 0"
                         size="mini"
                         type="success"
                         @click="revokeLeave(scope.row.id)">撤回
              </el-button>
              <el-button size="mini"
                         type="warning"
                         @click="leavePopup(scope.row, 2)">详情
              </el-button>
            </div>
            <div v-if="pageType === 2">
              <el-button :disabled="isTrueOrFalse(scope.row.begin_date) || scope.row.status !== 0"
                         type="primary"
                         size="mini"
                         @click="leavePopup(scope.row, 3)">审核
              </el-button>
            </div>
            <div v-if="pageType === 3">
              <el-button type="primary"
                         size="mini"
                         @click="leavePopup(scope.row, 2)">查看
              </el-button>
            </div>

            <!-- <el-button type="danger" @click="deleteTeachingManagement(scope.row.id)"
              >删除
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <el-pagination layout="prev,pager,next"
                     background
                     :page-count="page.pageCount"
                     :page-size="page.currentPage"
                     @current-change="loadLeave($event,pageType)"></el-pagination>

      <!-- <el-pagination
        layout="prev,pager,next"
        background
        :page-count="page.pageCount"
        :page-size="page.currentPage"
        @current-change="loadLeave(2)"
      ></el-pagination> -->

      <el-dialog :title="dialogName"
                 :visible.sync="addLeaveFormDialogVisible"
                 width="600px">
        <div class="center">
          <el-form :model="addLeaveForm"
                   ref="addLeaveFormRef"
                   label-width="120px"
                   :rules="addLeaveFormRules">
            <el-form-item v-if="pageType === 3"
                          label="姓名："
                          prop="mname">
              <el-input :disabled="detailDisabled"
                        type="text"
                        v-model="addLeaveForm.mname"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="开始时间："
                          prop="begin_date">
              <el-date-picker :disabled="detailDisabled"
                              v-model="addLeaveForm.begin_date"
                              type="datetime"
                              placeholder="开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间："
                          prop="end_date">
              <el-date-picker :disabled="detailDisabled"
                              v-model="addLeaveForm.end_date"
                              type="datetime"
                              placeholder="结束时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="请假类型："
                          prop="type">
              <el-select @change="typeChange"
                         clearable
                         filterable
                         :disabled="detailDisabled"
                         v-model="addLeaveForm.type"
                         placeholder="请选择类型">
                <el-option v-for="item in leaveTypeList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="请假原因："
                          prop="reason">
              <el-input :disabled="detailDisabled"
                        type="textarea"
                        v-model="addLeaveForm.reason"
                        placeholder="请输入请假原因"></el-input>
            </el-form-item>
            <el-form-item label="时长（天）"
                          prop="duration">
              <el-input @change="durationChange"
                        :disabled="detailDisabled"
                        v-model="addLeaveForm.duration"
                        placeholder="请输入数字"></el-input>
            </el-form-item>
            <el-form-item label="是否需要代课："
                          prop="is_dai">
              <el-select :disabled="detailDisabled"
                         v-model="addLeaveForm.is_dai"
                         placeholder="请选择是或否">
                <el-option v-for="item in is_daiList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审批人"
                          prop="list">
              <el-tag class="marginRight"
                      v-for="currentReviewer in addLeaveForm.list"
                      :key="currentReviewer.id">{{ currentReviewer.name }}
              </el-tag>
            </el-form-item>

            <el-form-item label="抄送人"
                          prop="list1">
              <el-input disabled
                        v-model="addLeaveForm.list1"
                        placeholder="抄送人"></el-input>
            </el-form-item>

            <el-form-item label="附件：">
              <el-upload class="upload-demo"
                         list-type="picture"
                         action="/api/base/uploadByOss.do"
                         :headers="{Authorization:token}"
                         :limit="1"
                         :on-remove="onRemoveFile"
                         ref="upload"
                         :on-success="enclosureUploadSuccess"
                         :data="{path:'leave',name:''}"
                         :show-file-list="true">

                <el-button size="small"
                           type="primary">点击上传</el-button>
                <div slot="tip"
                     class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </el-form-item>
            <!-- 意见 -->
            <el-form-item v-if="pageType === 2"
                          label="意见">
              <el-input type="textarea"
                        v-model="examineForm.fbcontent"
                        placeholder="请输入审核意见"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 确认与取消 -->
        <span v-if="!detailDisabled"
              slot="footer"
              class="dialog-footer">
          <el-button @click="addLeaveFormDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="LeaveFormConfirm">确 定</el-button>
        </span>
        <span v-if="pageType === 2"
              slot="footer"
              class="dialog-footer">
          <el-button @click="LeaveAgree(2)">拒绝</el-button>
          <el-button type="primary"
                     @click="LeaveAgree(1)">同意</el-button>
        </span>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import classSelector from "@/components/selectors/classSelector";
import termSelector from "@/components/selectors/termSelector";

export default {
  components: { classSelector, termSelector },
  name: "teachingManagement",
  data() {
    return {
      token: sessionStorage.getItem("token"),
      page: { pageCount: 1, currentPage: 0 },
      query: {
        queryTime: "",
        start_time: "",
        end_time: "",
        status: "",
      },
      // 申请状态列表
      apllyStatusList: [
        { id: 0, name: "发布成功" },
        { id: 1, name: "通过" },
        { id: 2, name: "拒绝" },
        { id: 3, name: "已撤销" },
      ],
      // apllyStatusList: [
      //   { id: 0, name: "申请中" },
      //   { id: 1, name: "审批通过" },
      //   { id: 2, name: "审批不通过" },
      // ],
      // 请假类型列表
      leaveTypeList: [
        { id: 1, name: "事假" },
        { id: 2, name: "病假" },
        { id: 3, name: "公假" },
        { id: 4, name: "婚假" },
        { id: 5, name: "丧假" },
        { id: 6, name: "产假" },
        { id: 7, name: "节育假" },
        { id: 8, name: "陪产假" },
        { id: 9, name: "看护假" },
      ],
      // 是否需要代课
      is_daiList: [
        { id: 0, name: "否" },
        { id: 1, name: "是" },
      ],
      // tab组件数据
      leaveTable: [],
      // 请假表单显示与隐藏
      addLeaveFormDialogVisible: false,
      // 请假表单
      addLeaveForm: {
        id: "",
        begin_date: "",
        end_date: "",
        duration: "",
        type: "",
        is_dai: "",
        reason: "",
        list: [],
        list1: "课程规划中心",
        url: "",
        // url: "https://img0.baidu.com/it/u=3731387020,1142375646&fm=26&fmt=auto&gp=0.jpg",
      },
      // 请假表单验证规则
      addLeaveFormRules: {
        begin_date: [{ required: true }],
        end_date: [{ required: true }],
        duration: [{ required: true }],
        type: [{ required: true }],
        is_dai: [{ required: true }],
        reason: [{ required: true }],
        list: [{ required: true }],
        // list1: [{ required: true }],
        url: [{ required: true }],
      },
      // 是否选中申请页面
      applyVisible: true,
      // 是否选中审核页面
      examineVisible: false,
      // 页面类型
      pageType: 1,
      // 弹出框表单是否禁用
      detailDisabled: false,
      dialogName: "",
      // 审核表单
      examineForm: {
        id: "",
        leave_id: "",
        status: "",
        fbcontent: "",
      },
      // 获取用户id
      userid: JSON.parse(sessionStorage.getItem("userInfo")).userid,
      // 权限名列表
      roleNameList: [],
      editDisabled: false,
    };
  },
  created() {
    // 查询账号权限
    this.queryAccountAuthority();
    this.loadLeave(1, 1);
    // console.log(1)
    console.log(this.isTrueOrFalse("2021-09-01", "2021-09-06"));
  },
  methods: {
    enclosureUploadSuccess(res, file) {
      this.addLeaveForm.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.addLeaveForm.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.addLeaveForm.url = "";
    },
    // 当前时间在开始时间之前可编辑，按钮不禁用
    isTrueOrFalse(begin_time) {
      let currentTime = new Date();
      let startTime = new Date(begin_time);
      // let endTime = new Date(end_time)
      if (currentTime >= startTime) {
        return true;
      } else {
        return false;
      }
      // if(currentTime >= startTime && currentTime <= endTime){
      //   return true
      // }else{
      //   return false
      // }
    },
    queryAccountAuthority() {
      this.$post("/base/queryLeaveRole.do").then((res) => {
        console.log(res);
        this.roleNameList = res.data;
        console.log(this.roleNameList);
      });
    },
    // 输入时长时
    durationChange(value) {
      this.getReviewerList();
    },
    // 选择请假类型
    typeChange(value) {
      this.getReviewerList();
    },
    // 得到审批人列表
    getReviewerList() {
      if (this.addLeaveForm.type !== "" && this.addLeaveForm.duration !== "") {
        let data = {
          type: parseInt(this.addLeaveForm.type),
          duration: this.addLeaveForm.duration,
        };
        this.$post("/base/queryLeaveReviewer.do", data).then((res) => {
          this.addLeaveForm.list = res.data;
          console.log(this.addLeaveForm.list);
        });
      }
    },
    // 获取初始页面数据
    loadLeave(page, type) {
      // console.log(parseInt(0))
      // console.log(parseInt(''))
      // console.log(page)

      const that = this;
      this.pageType = type;
      that.leaveTable = [];
      // 申请和查看
      let url = "/base/queryLeaveByTea.do";
      let data = {
        indexNo: page,
        start_time: this.query.queryTime[0],
        end_time: this.query.queryTime[1],
        // start_time: this.query.start_time,
        // end_time: this.query.end_time,
        status: this.query.status,
      };

      this.applyVisible = true;
      this.examineVisible = false;
      this.seeVisible = false;

      if (type === 2) {
        // 审核
        this.examineVisible = true;
        this.applyVisible = false;
        this.seeVisible = false;
        url = "/base/queryLeaveByGl.do";
        // if(page === 1){
        //   data.type = 1
        // }
        data.type = 1;
        // if(page !== 1){
        //   data.type = ''
        // }
        // console.log(data);
      } else if (type === 3) {
        // 查看
        this.examineVisible = false;
        this.applyVisible = false;
        this.seeVisible = true;
      }

      this.$post(url, data)
        .then((res) => {
          console.log(res);
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
          res.data.rows.forEach((item) => {
            item.duration = item.duration + "天";

            item.photoUrl = that.$fileUploadDomain + item.url;
            item.photoUrlList = [];
            item.photoUrlList.push(that.$fileUploadDomain + item.url);
          });
          that.leaveTable.push(...res.data.rows);

          console.log(that.leaveTable);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
      this.$nextTick(() => {
        this.$refs.leaveRef.doLayout();
      });
    },
    // 查看的查询状态下拉选择
    seeChange(value) {
      this.query.status = value;
      // console.log(value)
      console.log(this.query.status);
    },
    // 添加审批人
    addLeave() {
      this.dialogName = "添加请假信息";
      this.detailDisabled = false;
      this.addLeaveFormDialogVisible = true;
      this.$nextTick(() => {
        //   console.log(1)
        this.$refs.addLeaveFormRef.resetFields();
        this.$refs.upload.clearFiles();
        this.addLeaveForm.id = "";
        this.addLeaveForm.url = "";
      });
    },
    // 编辑 审核 查看
    leavePopup(row, type) {
      console.log(row);
      if (type === 1) {
        this.detailDisabled = false;
        this.dialogName = "编辑请假信息";
      } else if (type === 2) {
        // 查看
        this.detailDisabled = true;
        this.dialogName = "查看请假信息";
      } else if (type === 3) {
        // 审核
        this.detailDisabled = true;
        this.dialogName = "审核请假信息";

        row.list.forEach((item) => {
          if (item.managerid == this.userid) {
            this.examineForm = {
              id: item.id,
              leave_id: item.leave_id,
              status: row.status,
              fbcontent: "",
            };
          }
        });
      }
      // 显示对话框
      this.addLeaveFormDialogVisible = true;
      // 把数据赋值给表单
      this.$nextTick(() => {
        // str.replace(/[^0-9]/ig,"")
        this.addLeaveForm.id = row.id;
        this.addLeaveForm.begin_date = row.begin_date;
        this.addLeaveForm.end_date = row.end_date;
        this.addLeaveForm.duration = parseInt(row.duration);
        this.addLeaveForm.type = row.type;
        this.addLeaveForm.is_dai = row.is_dai;
        this.addLeaveForm.reason = row.reason;
        this.addLeaveForm.list = row.list;
        this.addLeaveForm.url = row.url;
        this.addLeaveForm.mname = row.mname;
        this.addLeaveForm.url = row.url;
        this.$refs.upload.clearFiles();
      });

      console.log(this.addLeaveForm);
    },
    // 同意
    LeaveAgree(type) {
      if (type == 1) {
        this.examineForm.status = 1;
      } else {
        this.examineForm.status = 2;
      }

      let data = this.examineForm;
      this.$post("/base/updateLeaveStatus.do", data).then((res) => {
        this.$message({
          message: "修改状态成功",
          type: "success",
        });
        console.log(res);
        this.addLeaveFormDialogVisible = false;
        this.loadLeave(1, 2);
      });
    },
    // 时间转换
    getTime(time) {
      let d = new Date(time);
      let year = d.getFullYear();
      let month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return `${year}-${month}-${date} ${hour}:${minutes}`;
    },
    // 表单提交
    LeaveFormConfirm() {
      this.$refs.addLeaveFormRef.validate((validate) => {
        if (validate) {
          // 编辑审批人表单提交
          // if (this.addLeaveForm.id) {
          //   this.$post(
          //     "/base/updateLessonPlan.do",
          //     this.addLeaveForm
          //   ).then((res) => {
          //     this.$message.success("编辑成功");
          //     this.addLeaveFormDialogVisible = false;
          //     // 更新当前页数据列表
          //     this.loadLeave(this.page.currentPage);
          //   });
          //   return;
          // }
          // 添加审批人表单提交

          const data = {
            begin_date: this.getTime(this.addLeaveForm.begin_date),
            end_date: this.getTime(this.addLeaveForm.end_date),
            duration: this.addLeaveForm.duration,
            type: this.addLeaveForm.type,
            is_dai: this.addLeaveForm.is_dai,
            reason: this.addLeaveForm.reason,
            url: this.addLeaveForm.url,
            list: this.addLeaveForm.list,
            // list1: this.addLeaveForm.list1,
          };
          console.log(data);
          this.$postJson("/base/insertLeave.do", data)
            .then((res) => {
              this.$message.success("添加成功");
              this.addLeaveFormDialogVisible = false;
              this.loadLeave(1, 1);
              console.log(res);
            })
            .catch((err) => {
              // that.$message.error(err.message.data);
            });
        }
      });
    },
    // 撤销请假
    revokeLeave(id) {
      const that = this;
      this.$confirm("是否撤销该请假记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$postJson(`/base/cancelLeave.do?id=${id}`)
            .then((res) => {
              that.$message({
                type: "success",
                message: "撤销成功!",
              });
              that.loadLeave(this.page.currentPage, this.pageType);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤销！",
          });
        });
    },
    // 删除
    deleteTeachingManagement(id) {
      const that = this;
      this.$confirm("是否删除教案管理?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$postJson("/base/deleteLessonPlan.do", { id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadLeave(this.page.currentPage);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  /* 180 */
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.marginBottom {
  margin-bottom: 20px;
}

.marginRight {
  margin-right: 6px;
}
</style>
